:root {
  --primary: #344767;
  --secondary: #2f80ed;
  --info: #98a8f8;
  --success: #27ae60;
  --warning: #f6b719;
  --danger: #eb5757;
  --pink: #ea4c89;
  --light: #f8f9fa;
  --gray: #646464;
  --dark: #323232;
  --muted: #98a6ad;
  --light: #f6f8fb;
  --white: #ffffff;
  --payroll:#f08a8a;
  --leave:#f59e47;
  --gray-light: #edf1f4;
  --primary-10: rgba(45, 73, 106, 0.1);
  --primary-15: rgba(45, 73, 106, 0.15);
  --primary-20: rgba(45, 73, 106, 0.2);
  --primary-25: rgba(45, 73, 106, 0.25);
  --primary-30: rgba(45, 73, 106, 0.3);
  --primary-40: rgba(45, 73, 106, 0.4);
  --primary-50: rgba(45, 73, 106, 0.5);
  --secondary-10: rgba(47, 128, 237, 0.1);
  --secondary-15: rgba(47, 128, 237, 0.15);
  --secondary-20: rgba(47, 128, 237, 0.2);
  --secondary-25: rgba(47, 128, 237, 0.25);
  --secondary-30: rgba(47, 128, 237, 0.30);
  --secondary-40: rgba(47, 128, 237, 0.40);
  --secondary-50: rgba(47, 128, 237, 0.50);
  --info-10: rgba(152, 168, 248, 0.1);
  --info-15: rgba(152, 168, 248, 0.15);
  --info-20: rgba(152, 168, 248, 0.2);
  --info-25: rgba(152, 168, 248, 0.25);
  --info-30: rgba(152, 168, 248, 0.30);
  --info-40: rgba(152, 168, 248, 0.40);
  --info-50: rgba(152, 168, 248, 0.50);
  --warning-10: rgba(246, 183, 25, 0.1);
  --warning-15: rgba(246, 183, 25, 0.15);
  --warning-20: rgba(246, 183, 25, 0.2);
  --warning-25: rgba(246, 183, 25, 0.25);
  --warning-30: rgba(246, 183, 25, 0.3);
  --warning-4: rgba(246, 183, 25, 0.4);
  --warning-50: rgba(246, 183, 25, 0.50);
  --danger-10: rgba(235, 87, 87, 0.1);
  --danger-15: rgba(235, 87, 87, 0.15);
  --danger-20: rgba(235, 87, 87, 0.20);
  --danger-25: rgba(235, 87, 87, 0.25);
  --danger-30: rgba(235, 87, 87, 0.3);
  --danger-35: rgba(235, 87, 87, 0.35);
  --danger-40: rgba(235, 87, 87, 0.4);
  --danger-45: rgba(235, 87, 87, 0.45);
  --danger-50: rgba(235, 87, 87, 0.5);
  --pink-10: rgba(234, 76, 137, 0.1);
  --pink-15: rgba(234, 76, 137, 0.15);
  --pink-20: rgba(234, 76, 137, 0.2);
  --pink-25: rgba(234, 76, 137, 0.25);
  --pink-30: rgba(234, 76, 137, 0.30);
  --pink-40: rgba(234, 76, 137, 0.40);
  --pink-50: rgba(234, 76, 137, 0.50);
  --success-10: rgba(39, 174, 96, 0.1);
  --success-15: rgba(39, 174, 96, 0.15);
  --success-20: rgba(39, 174, 96, 0.20);
  --success-25: rgba(39, 174, 96, 0.25);
  --success-30: rgba(39, 174, 96, 0.3);
  --success-40: rgba(39, 174, 96, 0.4);
  --success-50: rgba(39, 174, 96, 0.5);
}

@font-face { font-family: 'Nunito Sans'; font-style: normal; font-weight: 300; src: url("assets/fonts/NunitoSans.ttf");}
@font-face { font-family: 'Nunito Sans'; font-style: normal; font-weight: 400; src: url("assets/fonts/NunitoSans.ttf");}
@font-face { font-family: 'Nunito Sans'; font-style: normal; font-weight: 500; src: url("assets/fonts/NunitoSans.ttf");}
@font-face { font-family: 'Nunito Sans'; font-style: normal; font-weight: 600; src: url("assets/fonts/NunitoSans.ttf");}
@font-face { font-family: 'Nunito Sans'; font-style: normal; font-weight: 700; src: url("assets/fonts/NunitoSans.ttf");}
@font-face { font-family: 'Nunito Sans'; font-style: normal; font-weight: 800; src: url("assets/fonts/NunitoSans.ttf");}
@font-face { font-family: 'Nunito Sans'; font-style: normal; font-weight: 900; src: url("assets/fonts/NunitoSans.ttf");}
@font-face { font-family: 'Manrope'; font-style: normal; font-weight: 400; src: url("assets/fonts/Manrope.ttf");}

.mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: 'Nunito Sans', sans-serif !important;
  letter-spacing: normal;
}

.ag-theme-custom {
  font-family: 'Nunito Sans' !important;
}

.ag-theme-alpine .ag-root-wrapper {
  font-family: 'Nunito Sans' !important;
}

body {
  margin: 0;
  font-family: 'Nunito Sans', sans-serif !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 1.5 !important;
  color: #1d1d1d !important;
  background-color: #e9ecf5 !important;
  -webkit-text-size-adjust: 100% !important;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
}
a {
  text-decoration: none;
  color: #323232;
}
h1, h2, h3, h4, h5, h6 {
  font-weight: 700 !important;
}
.header.header-sticky {
  min-height: auto !important;
}
.btn-link:focus {
  box-shadow: none;
}
.ag-root-wrapper {
  border: none !important;
  border-radius: 8px 8px 8px 8px;
}

.ag-header{
  border-radius: 8px 8px 0px 0px;
}

.ag-body-viewport.ag-layout-normal::-webkit-scrollbar {
  width: 6px;
  border-radius: 5px;
}
.ag-body-viewport.ag-layout-normal::-webkit-scrollbar-track {
  background-color: rgba(51, 130, 187, 0.1);
}
.ag-body-viewport.ag-layout-normal::-webkit-scrollbar-thumb {
  width: 6px;
  background: #98a6ad;
  border-radius: 2.5px;
}
.avatar, .thumb {
  width: 40px;
  height: 40px;
}
.avatar-xs, .thumb-xs {
  width: 20px;
  height: 20px;
}
.avatar-sm, .thumb-sm {
  width: 30px;
  height: 30px;
}
.avatar-md, .thumb-md {
  width: 40px;
  height: 40px;
}
.avatar-lg, .thumb-lg {
  width: 50px;
  height: 50px;
}
.avatar-xl, .thumb-xl {
  width: 60px;
  height: 60px;
}
.avatar-xxl, .thumb-xxl {
  width: 70px;
  height: 70px;
}
.bg-primary, .panel .bg-primary, .auth-fluid .bg-primary {
  background-color: var(--primary) !important;
}
.bg-secondary, .panel .bg-secondary {
  background-color: var(--secondary) !important;
}
.bg-info, .panel .bg-info, .modal .bg-info {
  background-color: var(--info) !important;
}
.bg-success, .panel .bg-success {
  background-color: var(--success) !important;
}
.bg-warning, .panel .bg-warning {
  background-color: var(--warning) !important;
}
.bg-danger, .panel .bg-danger {
  background-color: var(--danger) !important;
}
.bg-light, .panel .bg-light {
  background-color: var(--light) !important;
}
.bg-dark, .panel .bg-dark {
  background-color: var(--dark) !important;
}
.bg-muted {
  background-color: var(--muted) !important;
}
.bg-payroll, .panel .bg-payroll {
  background-color: var(--payroll) !important;
}
.bg-leave, .panel .bg-leave {
  background-color: var(--leave) !important;
}
.bg-pink {
  background-color: var(--pink) !important;
}
.bg-primary-light {
  background-color: var(--primary-10) !important;
}
.bg-primary-15 {
  background-color: var(--primary-15) !important;
}
.bg-primary-20 {
  background-color: var(--primary-20) !important;
}
.bg-primary-25 {
  background-color: var(--primary-25) !important;
}
.bg-primary-30 {
  background-color: var(--primary-30) !important;
}
.bg-primary-40 {
  background-color: var(--primary-40) !important;
}
.bg-primary-50 {
  background-color: var(--primary-50) !important;
}
.bg-secondary-light {
  background-color: var(--secondary-10) !important;
}
.bg-secondary-15 {
  background-color: var(--secondary-15) !important;
}
.bg-secondary-20 {
  background-color: var(--secondary-20) !important;
}
.bg-secondary-25 {
  background-color: var(--secondary-25) !important;
}
.bg-secondary-30 {
  background-color: var(--secondary-30) !important;
}
.bg-secondary-40 {
  background-color: var(--secondary-40) !important;
}
.bg-secondary-50 {
  background-color: var(--secondary-50) !important;
}
.bg-info-light {
  background-color: var(--info-10) !important;
}
.bg-info-15 {
  background-color: var(--info-15) !important;
}
.bg-info-20 {
  background-color: var(--info-20) !important;
}
.bg-info-25 {
  background-color: var(--info-25) !important;
}
.bg-info-30 {
  background-color: var(--info-30) !important;
}
.bg-info-40 {
  background-color: var(--info-40) !important;
}
.bg-info-50 {
  background-color: var(--info-50) !important;
}
.bg-warning-light {
  background-color: var(--warning-10) !important;
}
.bg-warning-light-15, .bg-warning-15 {
  background-color: var(--warning-15) !important;
}
.bg-warning-20 {
  background-color: var(--warning-20) !important;
}
.bg-warning-25 {
  background-color: var(--warning-25) !important;
}
.bg-warning-30 {
  background-color: var(--warning-30) !important;
}
.bg-warning-40 {
  background-color: var(--warning-40) !important;
}
.bg-warning-50 {
  background-color: var(--warning-50) !important;
}
.bg-success-light {
  background-color: var(--success-10) !important;
}
.bg-success-15 {
  background-color: var(--success-15) !important;
}
.bg-success-20 {
  background-color: var(--success-20) !important;
}
.bg-success-25 {
  background-color: var(--success-25) !important;
}
.bg-success-30 {
  background-color: var(--success-30) !important;
}
.bg-success-40 {
  background-color: var(--success-40) !important;
}
.bg-success-50 {
  background-color: var(--success-50) !important;
}
.bg-danger-light {
  background-color: var(--danger-10) !important;
}
.bg-danger-15 {
  background-color: var(--danger-15) !important;
}
.bg-danger-20 {
  background-color: var(--danger-20) !important;
}
.bg-danger-25 {
  background-color: var(--danger-25) !important;
}
.bg-danger-30 {
  background-color: var(--danger-30) !important;
}
.bg-danger-40 {
  background-color: var(--danger-40) !important;
}
.bg-danger-50 {
  background-color: var(--danger-50) !important;
}
.bg-pink-light {
  background-color: var(--pink-10) !important;
}
.bg-pink-15 {
  background-color: var(--pink-15) !important;
}
.bg-pink-20 {
  background-color: var(--pink-20) !important;
}
.bg-pink-25 {
  background-color: var(--pink-25) !important;
}
.bg-pink-30 {
  background-color: var(--pink-30) !important;
}
.bg-pink-40 {
  background-color: var(--pink-40) !important;
}
.bg-pink-50 {
  background-color: var(--pink-50) !important;
}
.bg-gray-light {
  background-color: var(--gray-light) !important;
}
.border {
  border: solid 1px #e0e0e0;
}
.border-primary, .panel .border-primary {
  border: solid 1px var(--primary) !important;
}
.border-secondary, .panel .border-secondary {
  border: solid 1px var(--secondary) !important;
}
.border-info, .panel .border-info {
  border: 1px solid var(--info) !important;
}
.border-warning, .panel .border-warning {
  border: solid 1px var(--warning) !important;
}
.border-danger, .panel .border-danger {
  border: solid 1px var(--danger) !important;
}
.border-success, .panel .border-success {
  border: solid 1px var(--success) !important;
}
.border-pink {
  border: solid 1px var(--pink) !important;
}
.border-bottom-primary-light {
  border-bottom: 1px solid var(--primary-10);
}
.border-bottom-secondary-light {
  border-bottom: 1px solid var(--secondary-10);
}
.border-muted {
  border: 1px solid #bcbcbc !important;
}
.border-bottom-muted {
  border-bottom: 1px solid #bcbcbc !important;
}
.box-shadow {
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.08);
}
.btn {
  position: relative;
  /* font-size: 14px !important;
  padding: .396rem 12px !important; */
}
.ripple {
  overflow: hidden;
  background-position: center;
  transition: background 600ms;
}
.ripple:hover {
  background: rgba(255,255,255,.2) radial-gradient(circle, transparent 1%, rgba(255,255,255,.2) 1%) center/15000%;
}
.ripple:active {
  background-color: transparent;
  background-size: 100%;
  transition: background 0s;
}
.btn-default {
  color: var(--gray) !important;
  background-color: var(--white) !important;
  border-color: var(--muted) !important;
}
.btn-default:hover, .btn-default.active, .btn-default:active {
  color: var(--dark) !important;
  background-color: var(--white) !important;
  border-color: var(--dark) !important;
}
.btn-primary {
  color: #fff;
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
}
.btn-primary:hover {
  background-color: #1f3966 !important;
  border-color: #1f3966 !important;
}
.btn-secondary {
  color: #fff;
  background-color: var(--secondary) !important;
  border-color: var(--secondary) !important;
}
.btn-secondary:hover {
  background-color: #2273e0 !important;
  border-color: #2273e0 !important;
}
.btn-info {
  color: #fff !important;
  background-color: var(--info) !important;
  border-color: var(--info) !important;
}
.btn-info:hover {
  background-color: #8d9bdf !important;
  border-color: #8d9bdf !important;
}

.btn-warning {
  color: #fff !important;
  background-color: var(--warning) !important;
  border-color: var(--warning) !important;
}
.btn-warning:hover {
  background-color: #f0b916 !important;
  border-color: #f0b916 !important;
}
.btn-success {
  color: #fff;
  background-color: var(--success) !important;
  border-color: var(--success) !important;
}
.btn-success:hover {
  background-color: #19c461 !important;
  border-color: #19c461 !important;
}
.btn-danger {
  color: #fff;
  background-color: var(--danger) !important;
  border-color: var(--danger) !important;
}
.btn-danger:hover {
  background-color: #dd3030 !important;
  border-color: #dd3030 !important;
}
.btn-outline-primary {
  color: var(--primary) !important;
  background-color: var(--white) !important;
  border-color: var(--primary) !important;
}
.btn-outline-primary:hover {
  color: var(--white) !important;
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
}
.btn-outline-secondary {
  color: var(--secondary) !important;
  background-color: var(--white) !important;
  border-color: var(--secondary) !important;
}
.btn-outline-secondary:hover {
  color: var(--white) !important;
  background-color: var(--secondary) !important;
  border-color: var(--secondary) !important;
}
.btn-outline-warning {
  color: var(--warning) !important;
  background-color: var(--white) !important;
  border-color: var(--warning) !important;
}
.btn-outline-warning:hover {
  color: var(--white) !important;
  background-color: var(--warning) !important;
  border-color: var(--warning) !important;
}
.btn-outline-success {
  color: var(--success) !important;
  background-color: var(--white) !important;
  border-color: var(--success) !important;
}
.btn-outline-success:hover {
  color: var(--white) !important;
  background-color: var(--success) !important;
  border-color: var(--success) !important;
}
.btn-outline-danger {
  color: var(--danger) !important;
  background-color: var(--white) !important;
  border-color: var(--danger) !important;
}
.btn-outline-danger:hover {
  color: var(--white) !important;
  background-color: var(--danger) !important;
  border-color: var(--danger) !important;
}
.btn-outline-default {
  color: var(--muted) !important;
  background-color: var(--white) !important;
  border-color: var(--muted) !important;
}
.btn-outline-default:hover {
  color: var(--gray) !important;
  background-color: var(--light) !important;
  border-color: var(--gray) !important;
}
.c-pointer {
  cursor: pointer !important;
}
.c-move {
  cursor: move !important;
}
.dropdown-menu-end {
  left: auto;
  right: 0px;
}
.btn-group .dropdown-item:focus, .btn-group .dropdown-item:hover, .btn-group .dropdown-item.active, .btn-group .dropdown-item:active {
  color: var(--white) !important;
  background-color: var(--primary);
}
.btn-group .dropdown-menu {
  border-radius: 12px;
}
.btn-group .dropdown-menu li:nth-child(1) .dropdown-item {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}
.btn-group .dropdown-menu li:last-child .dropdown-item {
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}
.btn-icon {
  height: 38px;
  width: 38px;
}
.btn-icon-xs {
  width: 25px;
  height: 25px;
}
.btn-icon-sm {
  width: 30px;
  height: 30px;
}
.btn-icon i, .btn-icon-sm i, .btn-icon-xs i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}
/* .dropdown-item {
  font-size: 13px;
} */
.fw-light {
  font-weight: 400;
}
.fw-normal {
  font-weight: 500;
}
.fw-medium, .app-content-root .fw-medium, .modal-body .fw-medium {
  font-weight: 700 !important;
}
.fw-semibold {
  font-weight: 800 !important;
}
.fw-bold {
  font-weight: 800 !important;
}
.fw-900 {
  font-weight: 900 !important;
}
.fw-black {
  font-weight: 800;
}
hr {
  border-color: #e0e0e0;
  opacity: 1;
}
.lh-xs {
  line-height: 13px !important;
}
.mat-slide-toggle-bar {
  width: 40px !important;
  height: 18px !important;
  background-color: #fff !important;
  border: 1px solid #98a6ad;
}
.mat-slide-toggle-thumb {
  height: 15px !important;
  width: 15px !important;
  border-radius: 50% !important;
  display: block !important;
  background-color: #98a6ad !important;
  box-shadow: none !important;
  top: 4px !important;
  left: 2px !important;
  position: absolute !important;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: #344767 !important;
  border: 1px solid #344767 !important;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #fff !important;
  left: 6px !important;
}
.modal-md {
  max-width: 700px !important;
}
.modal-header {
  display: block !important;
  position: relative;
  background-color: #f8f8f8;
  border-bottom: 1px solid #e5e5e5 !important;
}
.modal-header .btn-close {
  padding: 0px !important;
  position: absolute;
  top: 30px;
  right: 30px;
}
.modal-header .btn-close:focus {
  box-shadow: none !important;
}
.modal-header .modal-title {
  margin-top: 30px;
}
.modal-header-icon {
  position: absolute;
  top: -32px;
  left: calc(50% - 32px);
  width: 64px;
  height: 64px;
  background-color: var(--white);
  border-radius: 100%;
  box-shadow: rgb(0 0 0 / 5%) 0px 1px 2px 0px;
}
.modal-header-icon i {
  display: block;
  width: 45px;
  height: 45px;
  background-size: 45px !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}
.modal-header-icon i::before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}
.ag-editable app-ag-grid-dropdown {
  width: 100% !important;
}
.ng-select-xs.ng-select.ng-select-single .ng-select-container {
  height: 34px !important;
  min-height: 34px !important;
  border-radius: 4px !important;
}
.ag-input-field-input.ag-text-field-input {
  padding: 0.15rem 0.625rem !important;
  font-size: .875rem !important;
  height: 34px !important;
  border-radius: 4px !important;
}
.ag-editable.child-det .ag-header-cell {
  padding: 0px 5px !important;
}



.icon-24 {
  width: 24px;
  height: 24px;
  background-size: 24px !important;
}
.icon-add {
  background: url(assets/img/add.svg) no-repeat;
}
.icon-view {
  background: url(assets/img/view_icon.png) no-repeat;
}
.icon-edit {
  background: url(assets/img/edit.svg) no-repeat;
}
.icon-success {
  background: url(assets/img/success.svg) no-repeat;
}
.icon-error {
  background: url(assets/img/error.svg) no-repeat;
}
.icon-warning {
  background: url(assets/img/warning.svg) no-repeat;
}
.icon-delete {
  background: url(assets/img/delete.svg) no-repeat;
}
.icon-activate {
  background: url(assets/img/activate.svg) no-repeat;
}
.icon-deactivate {
  background: url(assets/img/deactivate.svg) no-repeat;
}
.icon-resend {
  background: url(assets/img/resend.svg) no-repeat;
}
.icon-close-wizard {
  background: url(assets/img/close-wizard.svg) no-repeat;
}
.icon-apply {
  background: url(assets/img/apply.svg) no-repeat;
}
.icon-calculation {
  background: url(assets/img/calculation.svg) no-repeat;
}
.rounded-2x {
  border-radius: 8px !important;
}
.rounded-3x {
  border-radius: 12px !important;
}
.rounded-top-2x {
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
}
.rounded-bottom-2x {
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}
.tx-lato {
  font-family: Nunito Sans, sans-serif !important;
}
.tx-8 {
  font-size: 8px !important;
}
.tx-9 {
  font-size: 9px !important;
}
.tx-10 {
  font-size: 10px !important;
}
.tx-11 {
  font-size: 11px !important;
}
.tx-12 {
  font-size: 12px !important;
}
.tx-13 {
  font-size: 13px !important;
}
.tx-14 {
  font-size: 14px !important;
}
.tx-15 {
  font-size: 15px !important;
}
.tx-16 {
  font-size: 16px !important;
}
.tx-18 {
  font-size: 18px !important;
}
.tx-20 {
  font-size: 20px !important;
}
.tx-22 {
  font-size: 22px !important;
}
.tx-24 {
  font-size: 24px !important;
}
.tx-26 {
  font-size: 26px !important;
}
.tx-28 {
  font-size: 28px !important;
}
.tx-primary {
  color: var(--primary) !important;
}
.tx-secondary {
  color: var(--secondary) !important;
}
.tx-info {
  color: var(--info) !important;
}
.tx-success {
  color: var(--success) !important;
}
.tx-warning {
  color: var(--warning) !important;
}
.tx-danger {
  color: var(--danger) !important;
}
.tx-pink {
  color: var(--pink) !important;
}
.tx-dark {
  color: var(--dark) !important;
}
.tx-gray {
  color: var(--gray) !important;
}
.tx-muted {
  color: var(--muted);
}
.tx-white {
  color: var(--white);
}
textarea {
   resize: none !important;
}
.tooltip.show {
  opacity: 1 !important;
  z-index: 999999;
}
.tooltip .tooltip-inner {
  padding: 15px;
  background-color: #fdedc5;
  color: #323232;
  opacity: 1;
  min-width: 170px;
  max-width: 350px;
  width: 100%;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.08);
}
.tooltip .tooltip-inner p {
  text-align: left;
  line-height: 20px;
  font-weight: 600;
}
.tooltip .tooltip-inner .table thead {
  background-color: #ffdf8e !important;
}
.tooltip .tooltip-inner .table td, .tooltip .tooltip-inner .table th {
  border: 1px solid #c8a95f !important;
  text-align: left;
  padding: 5px;
}

.tooltip[data-popper-placement^=left] .tooltip-arrow::before {
  border-left-color: #fdedc5 !important;
  border-top-color: transparent !important;
}
.tooltip[data-popper-placement^=right] .tooltip-arrow::before {
  border-right-color: #fdedc5 !important;
  border-top-color: transparent !important;
}
.tooltip[data-popper-placement^=bottom] .tooltip-arrow::before {
  border-bottom-color: #fdedc5 !important;
  border-top-color: transparent !important;
}
.tooltip[data-popper-placement^=top] .tooltip-arrow::before {
  border-top-color: #fdedc5 !important;
  border-bottom-color: transparent !important;
}


.tooltip-secondary .tooltip-inner  {
  background-color: var(--secondary) !important;
}
.tooltip-warning .tooltip-inner  {
  background-color: var(--warning) !important;
}
.tooltip-danger .tooltip-inner  {
  background-color: var(--danger) !important;
}
.tooltip-secondary .tooltip-arrow::before {
  border-top-color: var(--secondary) !important;
}
.tooltip-warning .tooltip-arrow::before {
  border-top-color: var(--warning) !important;
}
.tooltip-danger .tooltip-arrow::before {
  border-top-color: var(--danger) !important;
}
.tooltip-secondary[data-popper-placement^=left] .tooltip-arrow::before {
  border-left-color: var(--secondary) !important;
  border-top-color: transparent !important;
}
.tooltip-warning[data-popper-placement^=right] .tooltip-arrow::before {
  border-right-color: var(--warning) !important;
  border-top-color: transparent !important;
}
.tooltip-danger[data-popper-placement^=right] .tooltip-arrow::before {
  border-right-color: var(--danger) !important;
  border-top-color: transparent !important;
}
.v-scroll {
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
}
.v-scroll::-webkit-scrollbar, .tab-inner-scroll::-webkit-scrollbar, .scroll-host::-webkit-scrollbar, .stepper-tab-content::-webkit-scrollbar {
  width: 6px;
  border-radius: 10px;
}
.v-scroll::-webkit-scrollbar-track, .tab-inner-scroll::-webkit-scrollbar-track, .scroll-host::-webkit-scrollbar-track, .stepper-tab-content::-webkit-scrollbar-track {
  background-color: rgba(51, 130, 187, 0.1);
}
.v-scroll::-webkit-scrollbar-thumb, .tab-inner-scroll::-webkit-scrollbar-thumb, .scroll-host::-webkit-scrollbar-thumb, .stepper-tab-content::-webkit-scrollbar-thumb {
  width: 6px;
  background: #98a6ad;
  border-radius: 10px;
}




.w-xxs {
  width: 60px !important;
}
.w-xs {
  width: 70px !important;
}
.w-sm {
  width: 100px !important;
}
.w-md {
  width: 125px !important;
}
.w-lg {
  width: 150px !important;
}
.w-xl {
  width: 175px !important;
}
.w-xxl {
  width: 200px !important;
}
/* Form Elements */
.form-control-label {
  margin: 0px 0px 4px;
  font-size: 14px;
  font-weight: 700;
}
.form-control-label em {
  font-style: normal;
  color: var(--danger);
}
.form-control, .form-select {
  padding: 0.375rem 1rem !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  border: solid 1px #bdbdbd !important;
}
.form-control:focus, .form-select:focus {
  border-color: #fff;
  box-shadow: rgb(0 0 0 / 16%) 0px 1px 2px, rgb(51 51 51) 0px 0px 0px 2px;
}
.form-control-sm, .form-select-sm {
  padding: 0.3rem 0.625rem !important;
  font-size: .875rem !important;
}
.form-control-xs {
  padding: 0.15rem 0.625rem !important;
  font-size: .875rem !important;
}
.input-group .form-control {
  border-top-left-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
}
.rounded-start-pill, .input-group .form-control.rounded-start-pill {
  border-top-left-radius: 50rem !important;
  border-bottom-left-radius: 50rem !important;
}
.rounded-end-pill, .input-group .form-control.rounded-end-pill {
  border-top-right-radius: 50rem !important;
  border-bottom-right-radius: 50rem !important;
}
.rounded-start-pill-0, .input-group .form-control.rounded-start-pill-0 {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.rounded-start-pill-0, .input-group .form-control.rounded-start-pill-equal {
  border-top-left-radius: 0.257rem !important;
  border-bottom-left-radius: 0.257rem !important;
}
.rounded-end-pill-0, .input-group .form-control.rounded-end-pill-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.input-group .btn {
  padding: 0px 15px !important;
  border-top-right-radius: 50rem !important;
  border-bottom-right-radius: 50rem !important;
  border-width: 1px 1px 1px 0px;
  border-color: #bdbdbd !important;
  border-style: solid;
}
.input-group .btn:disabled {
  background-color: rgb(235 235 235);
  border-color: rgb(235, 235, 235);
  opacity: 1;
  cursor: not-allowed !important;
}
input[type='checkbox'] {
  margin-right: 10px;
  position: relative;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  border: solid 2px #828282;
  cursor: pointer;
}
input[type='checkbox']::before {
  content: '';
  display: block;
  background: url(assets/img/check-white.svg) no-repeat;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 15px;
  width: 15px;
  transition: .25s all ease-in-out;
  border-radius: 4px;
  line-height: 12px;
  background-repeat: no-repeat;
  background-size: 15px;
  z-index: 9;
}
input[type='checkbox']:checked::after {
  content: '';
  background-color: #344767;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 4px;
}
.checkbox-sm input[type='checkbox'] {
  margin-right: 4px;
  position: relative;
  width: 16px;
  height: 16px;
  border-radius: 4px;
  border: solid 2px #828282;
  cursor: pointer;
}
.checkbox-sm input[type='checkbox']::before {
  content: '';
  display: block;
  background: url(assets/img/check-white.svg) no-repeat;
  position: absolute;
  top: 9px;
  left: 9px;
  height: 12px;
  width: 12px;
  transition: .25s all ease-in-out;
  border-radius: 4px;
  line-height: 15px;
  background-repeat: no-repeat;
  background-size: 10px;
  z-index: 9;
}
.checkbox-sm input[type='checkbox']:checked::after {
  content: '';
  background-color: #344767;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  border-radius: 4px;
}
input[type='radio'] {
  margin-right: 10px;
  position: relative;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  border: solid 2px #828282;
  cursor: pointer;
}
input[type='radio']:checked::after {
  content: '';
  background-color: #344767;
  pointer-events: none;
  position: absolute;
  top: 4px;
  left: 4px;
  height: 12px;
  width: 12px;
  border-radius: 100%;
}
input[type='radio']:checked::before {
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  border: solid 2px #344767;
}
.form-control::-webkit-input-placeholder, input::-webkit-input-placeholder, .ng-input input::-webkit-input-placeholder {
  color: #b5bec8 !important;
  font-weight: 500;
}
.form-control:-ms-input-placeholder, input:-ms-input-placeholder, .ng-input input:-ms-input-placeholder {
  color: #b5bec8 !important;
  font-weight: 500;
}
.form-control::placeholder, input::placeholder, .ng-input input::placeholder {
  color: #b5bec8 !important;
  font-weight: 500;
}
/* input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
} 
input[type=number] {
  -moz-appearance: textfield;
} */
.form-control:read-only {
  background-color: #ebebeb !important;
  border: 1px solid #ebebeb !important;
  cursor: not-allowed;
}
.form-control:read-only:focus {
  box-shadow: none !important;
}
.custom-date-range-picker .form-control:read-only, .custom-date-picker .form-control:read-only {
  border: solid 1px #bdbdbd !important;
  cursor: pointer;
  background-color: #fff !important;
}
.custom-date-range-picker .dropdown-menu, .custom-date-picker .dropdown-menu {
  padding: 12px;
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important;
  border: none;
}
.custom-date-range-picker .ngb-dp-header, .custom-date-picker .ngb-dp-header {
  margin: 0px 0px 10px;
  padding: 0px;
  border-radius: 0px;
  background-color: #fff;
}
.custom-date-range-picker .ngb-dp-arrow {
  width: auto;
  height: auto;
}
.custom-date-picker .ngb-dp-arrow {
  width: 25px;
  height: 25px;
}
.custom-date-range-picker .ngb-dp-arrow .btn {
  padding: 0px !important;
  margin: 0px;
  background-color: #fff;
  border: 1px solid #e9ecef;
  border-radius: 4px !important;
  width: 40px;
  height: 25px;
}
.custom-date-range-picker .ngb-dp-arrow .btn:hover, .custom-date-range-picker .ngb-dp-arrow .btn:active, .custom-date-picker .ngb-dp-arrow .btn:hover, .custom-date-picker .ngb-dp-arrow .btn:active {
  background-color: #e9ecef;
}
.custom-date-picker .ngb-dp-arrow .btn {
  padding: 0px !important;
  width: 25px;
  height: 25px;
  margin: 0px;
  border: 1px solid #e9ecef;
  border-radius: 4px !important;
}
.custom-date-range-picker .ngb-dp-navigation-chevron, .custom-date-picker .ngb-dp-navigation-chevron {
  margin: 0px auto !important;
  display: inline-block;
  color: #646464;
  width: 6px;
  height: 6px;
  border-width: 2px 2px 0 0;
  position: absolute;
  top: 9px;
  left: 18px;
}
.custom-date-range-picker .ngb-dp-arrow.right .ngb-dp-navigation-chevron {
  left: 15px;
}
.custom-date-picker .ngb-dp-navigation-chevron {
  top: 9px;
  left: 10px;
}
.custom-date-picker .ngb-dp-arrow.right .ngb-dp-navigation-chevron {
  left: 7px;
}
.custom-date-range-picker .form-select {
  margin: 0px 5px;
  padding: 0px 12px !important;
  height: 25px;
}
.custom-date-picker .form-select {
  margin: 0px 4px;
  padding: 0px 0px 0px 6px !important;
  height: 25px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url('https://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png');
  background-repeat: no-repeat;
  background-position: 44px center;
  background-size: 9px;
  position: relative;
}
.custom-date-picker .ngb-dp-day > div {
  width: 26px !important;
  height: 26px !important;
  line-height: 26px !important;
}
/* .custom-date-picker .ngb-dp-day[tabindex="0"] > div {
  background-color: var(--info) !important;
  color: #fff !important;
} */
.custom-date-range-picker .ngb-dp-month-name {
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  height: auto;
  background-color: #fff;
}
.custom-date-range-picker .ngb-dp-week, .custom-date-picker .ngb-dp-week {
  padding: 0px !important;
}
.custom-date-range-picker .ngb-dp-weekdays, .custom-date-picker .ngb-dp-weekdays {
  padding: 0px !important;
  border-bottom: none;
  background-color: #fff;
}
.custom-date-range-picker .ngb-dp-weekday, .custom-date-picker .ngb-dp-weekday {
  flex: 1 0 0%;
  color: var(--muted);
}
.custom-date-range-picker .ngb-dp-day, .custom-date-range-picker .ngb-dp-weekday, .custom-date-range-picker .ngb-dp-week-number, .custom-date-range-picker .custom-day, .custom-date-picker .ngb-dp-day, .custom-date-picker .ngb-dp-weekday, .custom-date-picker .ngb-dp-week-number, .custom-date-picker .custom-day {
  font-size: 14px;
  width: 26px;
  height: 26px;
  line-height: 20px;
}
.custom-date-range-picker .ngb-dp-weekday, .custom-date-range-picker .ngb-dp-week-number, .custom-date-picker .ngb-dp-weekday, .custom-date-picker .ngb-dp-week-number {
  font-style: normal !important;
}
.custom-date-range-picker .ngb-dp-month:nth-child(2) {
  padding-left: 10px;
}
.custom-date-range-picker .custom-day.range, .custom-date-range-picker  .custom-day:hover {
  background-color: #2f80ed;
  color: white;
}
.custom-date-range-picker .custom-day.faded {
  background-color: rgba(47, 128, 237, 0.20);
  color: #242424;
}
.ng-select .ng-select-container .ng-value-container .ng-input>input::placeholder {
  font-size: 14px;
}
.custom-date-picker .bg-primary {
 background-color: var(--primary) !important;
}
.custom-date-range-picker .ngb-dp-day.disabled, .custom-date-range-picker .ngb-dp-day.hidden {
  height: 0px !important;
}
.custom-date-picker.full-calander ngb-datepicker {
  width: 100%;
}
.custom-date-picker.full-calander .ngb-dp-header {
  margin: 0px;
  padding: 6px 10px;
  background-color: #e9ecf5;
}
.custom-date-picker.full-calander .ngb-dp-navigation-select {
  flex: 1 1 5rem;
}
.custom-date-picker.full-calander .ngb-dp-months {
  padding: 10px 0px;
}
.custom-date-picker.full-calander .ngb-dp-weekday {
  color: var(--dark);
  font-weight: 600;
}
.custom-date-picker.full-calander .ngb-dp-month {
  width: 100%;
}
.custom-date-picker.full-calander .ngb-dp-day {
  flex: 1 0 0%;
}
.custom-date-picker.full-calander .ngb-dp-day > div {
  width: 100% !important;
  height: 30px !important;
  line-height: 30px !important;
}
.custom-date-picker.full-calander .form-select {
  background-position: 85% center;
  border-color: #d3d7db !important;
  height: 30px;
}
.custom-date-picker.full-calander .ngb-dp-arrow {
  width: 30px;
  height: 30px;
}
.custom-date-picker.full-calander .ngb-dp-arrow .btn {
  width: 30px;
  height: 30px;
}
.custom-date-picker.full-calander .ngb-dp-navigation-chevron {
  width: 10px;
  height: 10px;
  border-width: 2.5px 2.5px 0 0;
}

input[type='checkbox'][disabled] {
  cursor: not-allowed;
}
input[type='checkbox'][disabled]:checked::after {
  background-color: #bdbdbd;
}
.ng-select .ng-select-container {
  border: solid 1px #bdbdbd;
  border-radius: 50rem !important;
}
.ag-theme-alpine .ng-select .ng-select-container {
  border-radius: 0px !important;
}
.ng-dropdown-panel {
  border: 1px solid #bdbdbd;
  box-sizing: border-box;
  border-radius: 20px !important;
  overflow: hidden;
  box-shadow: 1px 1px 4px rgb(0 0 0 / 20%);
  /* top: 110% !important; */
}
.ag-editable .ng-dropdown-panel {
  border-radius: 4px !important;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  display: flex !important;
  align-items: center;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option img {
  margin-right: 5px;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  color: var(--white) !important;
  background-color: var(--primary) !important;
  white-space: normal!important;
  transition: .5s;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  color: #1d1d1d !important;
  background-color: rgb(47 128 237 / 20%) !important;
  border-radius: 50rem !important;
  font-weight: 600;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon, .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-label {
  padding: 1px 8px !important;
}
.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
  display: flex;
  align-items: center;
}
.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value img {
  margin-right: 5px;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup {
  color: #646464 !important;
  background-color: rgb(47 128 237 / 15%);
}
.toast-container .ngx-toastr {
  margin-bottom: 5px !important;
  padding: 10px 15px 10px 40px !important;
  box-shadow: none !important;
  border-radius: 6px !important;
  width: 400px !important;
  background-size: 20px !important;
  background-position: 10px 14px !important;
}
.toast-close-button {
  color: #44504f !important;
}
.toast-title {
  font-weight: 600;
}
.toast-message {
  font-size: 14px;
}
.toast-success {
  background: #b6f8c4 url(assets/img/success.svg) no-repeat !important;
  color: #44504f !important;
}
.toast-warning {
  background: #fae7c7 url(assets/img/warning.svg) no-repeat !important;
  color: #44504f !important;
}
.toast-error {
  background: #ffb7b7 url(assets/img/error.svg) no-repeat !important;
  color: #44504f !important;
}
.toast-info {
  background: #cee9fd url(assets/img/info.svg) no-repeat !important;
  color: #44504f !important;
}
.toast-top-right {
  top: 70px !important;
}


/* Tables */
/* table {
  border-collapse: inherit;
} */
table>thead {
  background-color: #f4f4f6 /*#e6f0fd*/;
}
table th {
  font-size: 14px;
  font-weight: 600;
}
table.ui-datepicker-calendar>thead {
  background-color: #fff;
}
table td {
  font-size: 14px;
}
.table-fixed {
  table-layout: fixed;
}
.table-striped .thead th {
  background-color: #d7dde7 !important;
}
.table.rounded-2x thead tr:nth-child(1) th:first-child {
  border-top-left-radius: 8px;
}
.table.rounded-2x thead tr:nth-child(1) th:last-child {
  border-top-right-radius: 8px;
}
.table.rounded-2x tbody tr:last-child td:first-child {
  border-bottom-left-radius: 8px;
}
.table.rounded-2x tbody tr:last-child td:last-child {
  border-bottom-right-radius: 8px;
}
.table.align-middle th, .table.align-middle td, .table input[type='checkbox']  {
  vertical-align: middle;
}
/* .ag-header-viewport {
  position: relative;
} */
/* .ag-ltr .ag-cell {
  border-right-width: 1px;
  padding-left: 15px;
} */

.horz-hide .ag-body-horizontal-scroll {
  display: none;
}


@media (min-width: 768px) {
  .border-md-start {
  border-left: solid 1px #e0e0e0;
  }
  .border-md-end {
  border-right: solid 1px #e0e0e0;
  }
  .border-bottom-md-none {
  border-bottom: none !important;
  }
}


.toast-container{
  position: fixed !important;
}


.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button, .mat-flat-button, .mat-fab, .mat-mini-fab {

    font-family: Nunito Sans, sans-serif !important;

    font-size: 14px;

    font-weight: 500;

}

.mat-slide-toggle-content{
  font-family: Nunito Sans, sans-serif !important;
  }
   
  .ag-grid, .ag-grid-angular, .ag-grid-ng2, .ag-grid-polymer, .ag-grid-aurelia {
      display: block;
      font-family: 'Nunito Sans' !important;
  }
  .mat-tab-group {
      font-family: Nunito Sans, sans-serif !important;
   
  }
  .ag-theme-alpine, .ag-theme-alpine-dark{
  font-family: Nunito Sans, sans-serif !important;
   
  }
   
  .ag-theme-customscheduling{
  font-family: Nunito Sans, sans-serif !important;
   
  }


