[data-tooltip]:before {
  content: attr(data-tooltip);
  display: none;
  position: fixed;
  margin: 10px 10px 10px 10px;
}
.align-pop popover-container {
  position: absolute !important;
  will-change: unset !important;
  transform: none !important;
  top: -150px !important;
  left: 28px !important;
}

.align-pop {
  position: relative;
}
.GridFooter {
  background-color: #8c8c8c !important;
  color: white;
  font-weight: 700;
}
.app-content {
  -webkit-overflow-scrolling: touch;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  overflow-x: hidden;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  transition: all 300ms;
  -moz-transition: all 300ms;
  -webkit-transition: all 300ms;
}
.app-header {
  background-color: #344767;
}
.footer {
  padding: 0px !important;
  border: none !important;
  background-color: #fff !important;
  box-shadow: inset 0 2px 2px 0 rgba(0, 0, 0, 0.1);
}
.navbar-brand {
  font-size: 2rem !important;
}
.app-header .navbar-brand img {
  width: 140px;
  height: 50px;
}
.app-header .nav-link {
  padding: 0rem 0.75rem !important;
  color: #fff !important;
  font-size: 16px;
  line-height: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 150px;
}
.app-header .nav-link.active {
  color: #f6b719 !important;
}
.app-header .nav-link.active.dropdown-toggle::after {
  border-color: #f6b719;
}
.app-header .dropdown-menu {
  border: none;
  min-width: 250px;
  box-shadow: 0 4px 4px 0 rgb(0 0 0 / 10%);
}

.app-header .header-right .dropdown-menu {
  max-height: 300px;
  overflow-y: auto;
}

.app-header .header-right .dropdown-menu::-webkit-scrollbar {
  width: 6px;
  border-radius: 10px;
}

.app-header .header-right .dropdown-menu::-webkit-scrollbar-track {
  background-color: rgba(51, 130, 187, 0.1);
}

.app-header .header-right .dropdown-menu::-webkit-scrollbar-thumb{
  width: 6px;
  background: #98a6ad;
  border-radius: 10px;
}

.app-header .dropdown-menu .dropdown-item {
  font-size: 16px;
  color: #1d1d1d;
  line-height: 30px;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 45ch;
}
.app-header .dropdown-menu li.dropdown .dropdown-toggle::after {
  margin-left: 8px;
  border: solid #4f4f4f;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  position: absolute;
  top: 15px;
  right: 18px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}
.app-header .dropdown-menu li.dropdown .dropdown-toggle:hover::after {
  border: solid #fff;
  border-width: 0 2px 2px 0;
}
.app-header .dropdown-item:active,
.app-header .dropdown-item:hover,
.app-header .dropdown .dropdown-menu > li.dropdown:hover {
  background-color: #344767;
  color: #fff;
}
.app-header .dropdown-menu li:last-childli.dropdown:hover {
  border-bottom-right-radius: 0px;
}
.app-header .dropdown-menu li.dropdown:hover > .dropdown-toggle {
  color: #fff;
}
.app-header .dropdown-menu li.dropdown:hover > .dropdown-toggle::after,
.app-header .sub-dropend > .dropdown-toggle:hover::after {
  border: solid #fff;
  border-width: 0 2px 2px 0;
}
.app-header li.dropdown .dropdown-menu .dropdown-item:hover {
  background-color: #344767 /* rgba(31, 57, 102, 0.7) */;
}
.app-header .dropdown-toggle::after {
  margin-left: 8px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  position: relative;
  top: -2px;
}
.app-header .dropdown-menu {
  padding: 0px;
}
.header-right .dropdown-toggle::after {
  display: none;
}
.header-right .nav-link {
  padding: 0px 0.625rem !important;
  cursor: pointer !important;
  outline: none !important;
}
.app-header .header-right .dropdown-menu .dropdown-item.active {
  color: #fff !important;
  background-color: #344767 !important;
}
.header-right .dropdown-item {
  cursor: pointer;
}
.app-content-root {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  flex-grow: 1;
}
.breadcrumb li {
  font-size: 15px;
}
.breadcrumb-item a {
  color: #4f4f4f;
  text-decoration: none !important;
}
.breadcrumb .breadcrumb-item.active {
  color: #282828;
  font-weight: 700;
}
.breadcrumb li a:hover {
  color: #2f80ed;
}
.breadcrumb-item + .breadcrumb-item::before {
  content: "" !important;
  border: solid #4f4f4f;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 3px !important;
  margin: 0px 12px 0px -3px;
  position: relative;
  top: 7px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}
.panel-tab {
  border-bottom: 1px solid #bdbdbd !important;
}
.panel-tab .nav-link {
  padding: 0.5rem 1.5rem;
  color: #4f4f4f !important;
  font-size: 16px;
  font-weight: 500;
  line-height: 32px;
  border-width: 0px 0px 3px 0px !important;
  border-color: transparent !important;
  border-style: solid !important;
  cursor: pointer;
}
.panel-tab .nav-link.active {
  font-weight: 700;
  color: #f6b719 !important;
  border-color: #f6b719 !important;
}
.search-with-icon .form-control {
  padding-left: 40px !important;
}
.search-with-icon i {
  position: absolute;
  top: 10px;
  left: 15px;
  opacity: 0.5;
}
.search-with-icon .form-control:focus > i {
  opacity: 1;
}
.table-wrapper {
  height: calc(100vh - 230px);
  display: flex;
  flex-direction: column;
}
.table-footer .btn {
  position: relative;
  padding: 0px;
  width: 34px;
  height: 34px;
}
.table-footer .btn i {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.table-footer .input-group .btn {
  padding: 0px !important;
}
.modal-header {
  border-bottom: 1px solid #828282;
}
.upload-files-container h5 {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.58;
  color: #092c4c;
}
.drag-file-area {
  border: 2px dashed #828282;
  border-radius: 20px;
  margin: 0px;
  padding: 30px 20px;
  text-align: center;
  width: 165px;
}
.drag-file-area i {
  margin-bottom: 20px;
  color: #828282;
}
.drag-file-area h6,
.drag-file-area .divider {
  margin: 0px;
  font-size: 14px;
  line-height: 1.4;
  font-weight: 500;
  color: #333;
}
.drag-file-area label {
  cursor: pointer;
}
.dropdown-menu {
  margin-top: 0;
}
.dropdown-menu .dropdown-toggle::after {
  vertical-align: middle;
  border-left: 4px solid;
  border-bottom: 4px solid transparent;
  border-top: 4px solid transparent;
}
.dropdown-menu .dropdown .dropdown-menu {
  left: 100%;
  top: 0%;
  margin: 0 20px;
  border-width: 0;
}
.dropdown-menu .dropdown .dropdown-menu.left {
  right: 100%;
  left: auto;
}
.default-file-input {
  opacity: 0;
  display: block;
  width: 0px;
  height: 0px;
}
.browse-files-text {
  color: #1f3966;
  font-weight: 700;
}
.uploaded-file {
  border: 2px dashed #d8dbe0;
  padding: 10px;
  width: 165px;
  height: 165px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: pointer;
  overflow: hidden;
}
/* .browse-files {
  opacity: 0;
  font-size: 70px;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgb(13 13 13 / 60%);
  width: 165px;
  height: 165px;
  -webkit-transition: all 400ms ease-out;
  -moz-transition: all 400ms ease-out;
  -o-transition: all 400ms ease-out;
  -ms-transition: all 400ms ease-out;
  transition: all 400ms ease-out;
  text-align: center;
}
.uploaded-file .browse-files:hover {
  opacity: 1;
}
.uploaded-file .browse-files .c-pointer {
  height: 0;
  opacity: 1;
  transition-delay: 0s;
  transition-duration: 0.4s;
}
.uploaded-file .browse-files:hover .c-pointer {
  opacity: 1;
  transform: translateY(250px);
  -webkit-transform: translateY(250px);
} */
.legend {
  width: 24px;
  height: 24px;
  line-height: 24px;
}
.legend-sm {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.day-group-calendar .legend {
  margin: 0px auto;
  min-width: auto;
  padding: 0px 8px;
  height: 20px;
  line-height: 20px;
  max-width: fit-content;
}
.day-group-calendar {
  height: 570px;
}
.day-group-calendar .ag-header-cell-text {
  display: block;
  width: 100%;
  text-align: center;
}
.day-group-calendar .ag-cell-value {
  display: block;
  text-align: center;
  line-height: 20px;
}
.wizard-video {
  height: 380px;
}
.text-28{
  font-size: 28px;
}
.text-18{
  font-size: 18px;
}
/* .day-group-calendar th {
  padding: .3rem .2rem;
  text-align: center;
}
.day-group-calendar td {
  line-height: 16px;
  padding: .0rem .2rem .3rem;
  text-align: center;
}
.day-group-calendar tbody th {
  padding: .0rem .2rem .3rem;
  font-size: 13px;
  line-height: 14px;
} */
.legend-fullday {
  background-color: rgba(246, 183, 25, 0.1) !important;
}
.legend-halfday {
  background-color: rgba(235, 87, 87, 0.1) !important;
}
.legend-restday {
  background-color: rgba(39, 174, 96, 0.12) !important;
}
.legend-holiday {
  background-color: rgba(47, 128, 237, 0.12) !important;
}
.legend-in-time {
  background-color: #c9ead7 !important;
}
.legend-leaving-time {
  background-color: #fad5d5 !important;
}
.legend-overtime {
  background-color: #c5daf6 !important;
}
.stepper-tab {
  flex-wrap: wrap;
  display: flex;
  align-items: center;
}
.stepper-tab li {
  display: inline-flex;
  align-items: center;
}
.stepper-tab li::after {
  content: "";
  margin-left: 15px;
  display: inline-block;
  width: 50px;
  height: 2px;
  background-color: #98a6ad;
}
.stepper-tab li:last-child::after {
  display: none;
}
.stepper-tab li a {
  color: #98a6ad !important;
  border: none !important;
  padding: 0.5rem 0rem 0.5rem 1rem !important;
}
.stepper-tab li a .count {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-line-pack: center;
  align-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 2em;
  height: 2em;
  padding: 0.5em 0;
  line-height: 1em;
  color: #fff;
  background-color: #98a6ad;
  border-radius: 1em;
}
.stepper-tab li a.active {
  margin-bottom: 0px;
  border: none;
  color: #f6b719 !important;
  font-weight: 700;
}
.stepper-tab li a.active .count {
  background-color: #f6b719;
}
.stepper-tab-content {
  height: calc(100vh - 236px);
  overflow-y: auto;
}
.stepper-tab li a:hover {
  border: none;
}
.accordion-button::after {
  width: 0 !important;
  height: 0 !important;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid #1f3966;
  background-image: none !important;
}
.tab-inner-scroll {
  height: calc(100vh - 255px);
  overflow-y: auto;
}
.profile-thumb {
  margin: 0px auto;
  display: block;
  position: relative;
  width: 120px;
  height: 145px;
  /* background: url(assets/img/user-default-profile.png) no-repeat; */
  background-color: #edf1f4;
  background-size: cover;
  background-position: center;
  border-radius: 8px;
  border: 1px solid #dee2e6;
}
.profile-thumb img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 8px;
}
.custom-file-input {
  position: absolute;
  width: 100%;
  height: 100%;
}
.custom-file-input input {
  width: 100%;
  height: 100%;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: 10;
  cursor: pointer;
}
.custom-file-input label {
  width: 100%;
  height: 100%;
  position: absolute;
  cursor: pointer;
}
.custom-file-input label span {
  margin: 0px;
  cursor: pointer;
  width: 30px;
  height: 30px;
  position: absolute;
  right: -15px;
  bottom: -12px;
  box-shadow: 0 4px 4px 0 rgb(0 0 0 / 8%);
  background-color: #f6f8fb;
  border-radius: 100%;
}
.custom-file-input label span i {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.btn-file input {
  width: 100%;
  height: 100%;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 10;
  cursor: pointer;
}
.nav-pills .nav-link {
  font-size: 16px;
  line-height: 1.4;
  color: #282828;
}
.nav-pills .nav-link.active {
  font-weight: 700;
  color: #000 !important;
  border: solid 1px #f6b719;
  background-color: rgba(246, 183, 25, 0.25) !important;
}
.input-group.rounded-pill .input-group-text {
  border-top-right-radius: 50rem;
  border-bottom-right-radius: 50rem;
}
.input-group.rounded-pill .input-group-text.readonly {
  background-color: rgb(235 235 235);
  cursor: not-allowed;
}
.rest-box {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 35px;
}
app-emp-name-render > div > div {
  min-height: 72px;
}
.schedule-table-wrapper ul {
  height: 100%;
}
.schedule-table-wrapper ul li {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.schedule-wrapper table thead th {
  min-width: 175px;
  width: 72%;
}
.schedule-wrapper table thead th:nth-child(1) {
  min-width: 30px;
  width: 30px;
}
.schedule-wrapper table thead th:nth-child(2) {
  min-width: 130px;
  width: 10%;
}
.schedule-wrapper table tbody tr td:nth-child(1) ul {
  /* min-height: 142px; */
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
}
.schedule-wrapper table tbody tr td:nth-child(1) ul li {
  display: flex;
  padding: 0px;
  justify-content: center;
  position: absolute;
  height: 50%;
  left: 0px;
  right: 0px;
  top: 0px;
  border: none;
}
.schedule-wrapper table tbody tr td:nth-child(1) ul li:nth-child(1) {
  top: auto;
  bottom: 0px;
}
.schedule-wrapper table tbody tr td .form-control {
  padding: 2px 5px !important;
  min-height: auto;
  font-size: 10px !important;
}
.rest-day .ag-cell-wrapper,
.rest-day .ag-cell-value {
  min-height: 80px;
  height: 100%;
}
.schedule-table-wrapper .ag-popup-editor {
  background-color: #fff;
  border: none;
  border-radius: 8px !important;
  box-shadow: 0 8px 8px 0 rgb(0 0 0 / 10%);
}
.schedule-table-wrapper app-custom-header {
  width: 100%;
}
.panel-header-template-5 .v-scroll {
  height: calc(100vh - 340px);
}
.month-calendar .current-date,
.month-calendar .current-date:hover {
  color: #fff;
  background-color: #98a8f8 !important;
  border-radius: 4px;
}
.month-calendar td {
  cursor: pointer;
}
.month-calendar td:hover {
  background-color: #edf1f4 !important;
}
.month-calendar .date-disabled {
  cursor: default;
  opacity: 0.3;
}
.custom-file-box {
  position: relative;
}
.custom-file-box input[type="file"] {
  position: absolute;
  visibility: hidden;
  width: 100%;
  height: 100%;
}
.custom-file-box label {
  padding: 6px 20px;
  font-size: 14px;
  border-radius: 50rem;
  border: 1px solid #bdbdbd;
  cursor: pointer;
}
.ag-row-selected::before {
  background-color: #e9f2fd !important;
}
.ag-checkbox-input-wrapper.ag-checked::after {
  color: #344767 !important;
}
.ag-checkbox-input-wrapper input,
.ag-checkbox-input-wrapper input {
  margin-right: 0px !important;
}
.custom-tab-vertical {
  width: 100%;
}
.custom-tab-vertical li a {
  color: #323232;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
}
.custom-tab-vertical li a.active {
  background-color: #fff;
}
.bank-logo-wrapper img {
  height: 100px;
  width: 100%;
  object-fit: scale-down;
}
.payslip-wrapper .divider {
  width: 100%;
  height: 1px;
  background-color: #98a8f8;
}
/* .ag-cell-value, .ag-group-value {
  line-height: 20px;
} */
.payslip-generation .table-wrapper {
  height: auto !important;
}
.setup-wizard-wrapper .nav {
  flex-direction: column;
}
.setup-wizard-wrapper .nav .nav-link {
  margin: 0px;
  padding: 8px 15px;
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #323232;
  text-align: left;
  border: none;
  background-color: transparent;
  border-left: 5px solid transparent;
}
.setup-wizard-wrapper .nav .nav-link.active,
.setup-wizard-wrapper .nav .nav-link:hover {
  color: #f6b719;
  border-left: 5px solid #f6b719;
}
.master-nav>li a {
  position: relative;
}
.master-nav>li>a::after {
  content: '';
  border: solid #212529;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  position: absolute;
  right: 26px;
  top: 17px;
}
.master-nav .nav-link {
  padding: 0.5rem 1.5rem;
  color: #212529 !important;
  font-size: 16px;
  border-left: 4px solid transparent;
}
.master-nav .nav-link.active, .master-nav li ul li a:hover {
  color: #f6b719 !important;
}
.master-nav .nav-link[aria-expanded="true"], .master-nav .nav-link:hover[aria-expanded="false"] {
  font-weight: 700;
  color: #212529 !important;
}
.master-nav .nav-link[aria-expanded="true"] {
  border-left: 4px solid #f6b719;
}
.master-nav .nav-link[aria-expanded="true"]::after {
  top: 15px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
.master-nav li ul {
  margin-bottom: 10px;
  display: block;
  -webkit-transition: max-height, 0.8s ease-in-out;
  transition: max-height, 0.8s ease-in-out;
}
.master-nav li ul li a {
  padding: 0.25rem 1.5rem !important;
  border-left: none;
}
.bg-annual-light {
  background-color: #e1ebf4 !important;
}
.bg-medical-light {
  background-color: #fdeeee !important;
}
.bg-ml-claim-light {
  background-color: #fef4dd !important;
}
.bg-detal-claim-light {
  background-color: #f0f2fe !important;
}
.bg-annual-dark {
  background-color: #99bddb !important;
}
.bg-medical-dark {
  background-color: #f8c4c4 !important;
}
.bg-ml-claim-dark {
  background-color: #fbdb8c !important;
}
.bg-detal-claim-dark {
  background-color: #cbd3fb !important;
}
.mon-emp-list .card-body.p-0 {
  padding: 20px !important;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
  top: 7px !important;
}
b, strong {
  font-weight: 800 !important;
}
.mat-date-picker app-datepicker {
  width: 100%;
}
.mat-date-picker .mat-form-field {
  width: 100%;
}
.mat-date-picker .mat-form-field-flex {
  display: flex !important;
  height: 36px;
}
.mat-date-picker .mat-form-field-appearance-outline .mat-form-field-outline {
  height: 36px;
}
.mat-date-picker .mat-form-field-wrapper {
  margin: 0px !important;
  padding: 0px !important;
}
.mat-date-picker .mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 0px !important;
}
.mat-date-picker .mat-form-field-label-wrapper {
  top: 0 !important;
  padding-top: 0em !important;
}
.mat-date-picker .mat-form-field-infix {
  top: -0.15rem;
}
.mat-date-picker .mat-input-element, .mat-icon-button.mat-button-disabled.mat-button-disabled {
  font-family: 'Nunito Sans', sans-serif !important;
}
.mat-input-element:disabled {
  color: #000000 !important;
}
.mat-date-picker .mat-form-field-appearance-outline .mat-form-field-outline-start, .mat-date-picker .mat-form-field-appearance-outline .mat-form-field-outline-end {
  border: 1px solid #ccc !important;
  border-radius: 50rem !important;
}
.mat-date-picker .mat-form-field-appearance-outline .mat-form-field-outline-start {
  display: none !important;
}
.mat-datepicker-content {
  border-radius: 8px !important;
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important;
}
.mat-calendar {
  font-family: 'Nunito Sans', sans-serif !important;
}
.mat-calendar-content {
  padding: 0 0px 8px 0px !important;
}
.mat-calendar-controls {
  margin: 0px 0px 15px !important;
}
.mat-calendar-table-header-divider::after {
  display: none;
}
.mat-calendar-table-header tr:last-child {
  display: none !important;
}
.mat-calendar-table-header th {
  padding: 8px 0px !important;
}
.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgb(52 71 103) !important;
  background-color: #344767 !important;
  color: #fff !important;
}
.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgb(52 71 103) !important;
}
.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: #98a8f8 !important;
  color: #fff !important;
  background-color: #98a8f8 !important;
}
.mat-icon-button {
  background-color: transparent !important;
}
.mat-datepicker-toggle-active {
  color: rgba(0,0,0,.54) !important;
}
.mat-calendar-body-selected {
  background-color: #98a8f8 !important;
}

.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  background-color: #f9f9f9;
  border-radius: 50rem;
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline .mat-form-field-outline-end {
  border: 1px solid #ebebeb !important;
}

.ag-cell-wrapper>*:not(.ag-cell-value):not(.ag-group-value) {
  margin-left: 2px;
}
.ag-center-cols-viewport {
  overflow-x: hidden;
}
.ag-editable .editable span.ag-cell-value {
  line-height: 14px !important;
}
.child-det .input-group.custom-date-picker.w-100 {
  max-width: 200px!important;
}
.child-det.ag-editable .editable span.ag-cell-value {
  border: 1px solid rgba(0, 0, 0, 0.2901960784);
  padding: 10px 10px;
  height: 40px;
  font-weight: 700;
  font-family: 'Nunito Sans', sans-serif !important;
  font-size: 15px;
  line-height: 20px !important;
  white-space: nowrap;
}
.ag-ltr .ag-cell-focus:not(.ag-cell-range-selected):focus-within {
  border: 1px solid transparent !important;
}
.ng-select .ng-clear-wrapper {
  top: 2px;
}
.tutorial-thumnail {
  position: relative;
  display: inline-block;
  /* width: 464px;
  height: 347px; */
  background: #000;
  background-size: cover;
  background-position: center;
  cursor: pointer;
  position: relative;
}



.tutorial-thumnail::before {
  content: "";
  background: url(assets/images/youtube-play.png) no-repeat;
  width: 32px;
  height: 32px;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-position: center;
  background-size: contain;
}

.tutorial-thumnail img{
  width: 100%;
  height:100%;
  object-fit: contain;
}

.yt .modal-content {
  background: transparent!important;
  border: none!important;
}

.yt iframe {
  margin: auto;
  display: block;
}

/**24/7/2023**/

.ag-header-cell:last-child {
  --ag-header-column-separator-color: none;
}

.payroll-sec .empWizard .panel {
  height: calc(100vh - 300px)!important;
}
.att-adj .empWizard .panel .att-table {
  height: calc(100vh - 400px)!important;
}

.child-det input{
  height: 40px;
  border-radius: 0px!important;
}

/*.ag-center-cols-container {
  width: 100%!important;
}*/
.empWizard.nz-wizard .panel{
  height:64vh!important;
}


.nz-wizard table th {
  text-align: left;
}

.nz-wizard .nz-btn{
  height: 30px;
  padding-top: 2px;
}

.att-adj .ag-header-group-cell-label, .att-adj .ag-header-cell-label {
  justify-content: center;
}

.pay-det .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  top: 0px;
}

body{
  overflow-x: hidden !important;
}

/*.leave-his .ag-body-viewport{
  margin-bottom: 100px;
}*/

.country-cd-ng .ng-dropdown-panel .ng-dropdown-footer {
display: none;
}

.pay-det .ag-popup-editor input{
  display: none;
}

.txt-len{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 150px;
}

span.txt-len{
  white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 200px;
    display: inline-block;
}

.accordion-item .ag-selection-checkbox{
  margin-left: -5px;
}
.ag-v-scroll .ag-body-horizontal-scroll-viewport {
  overflow-x: hidden !important;
}
/***responsive***/

@media (max-width: 767.98px) {
  .app-header .navbar-brand img {
    width: 125px;
    height: 45px;
  }
  .app-header .nav-link {
    padding: 0rem 0.5rem !important;
  }
  .panel-tab .nav-link {
    padding: 0.625rem 1rem;
  }

}

@media (max-width: 991.98px) {
  .app-header {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    z-index: 1035;
  }
  .app-header .navbar-collapse {
    position: fixed;
    top: 50px;
    left: 0px;
    right: 0px;
    background-color: rgb(255, 255, 255);
    box-shadow: rgb(0 0 0 / 10%) 0px 2px 2px 0px;
    z-index: 1035;
    max-height: 550px;
    overflow-x: hidden;
    overflow-y: auto;
    border-top: 2px solid #eaeaea;
  }
  .navbar > .container-fluid {
    justify-content: flex-start;
  }
  .app-header .container-fluid > .btn {
    padding: 0px;
    margin-right: 20px;
  }
  .app-header .nav-link {
    padding: 0.5rem 0.75rem !important;
    color: #4f4f4f !important;
    line-height: 24px;
    height: auto;
    display: block;
  }
  .header-right .nav-link {
    color: #fff !important;
  }
  .app-header .dropdown-toggle::after {
    position: absolute;
    right: 18px;
    top: 16px;
    border-color: #4f4f4f;
  }
  .app-header .dropdown-menu {
    padding: 0px 0px 0px 20px;
  }
  .header-right .nav-link {
    padding: 0px 0.625rem !important;
  }
  .header-right .nav-link {
    height: 50px;
    display: flex;
  }
  .header-right .dropdown-menu {
    position: absolute;
    top: 54px;
    z-index: 9999;
    padding-left: 0px;
  }
  .app-content-root {
    padding-top: 58px;
  }
  .table-wrapper,
  .tab-inner-scroll,
  .schedule-table-wrapper {
    height: auto;
  }
  .v-scroll,
  .tab-inner-scroll {
    overflow-y: clip;
  }
  .table-collapse {
    table-layout: auto;
  }
  .navbar-nav .dropdown-menu {

    float: left;
    width: 100%;
    padding: 0;
    background: #e6e6e6;
}
.app-header .dropdown-menu {
  border: none;
  min-width: 250px;
  box-shadow: none;
}
.dropdown-menu .dropdown .dropdown-menu {
  left: 0!important;
  top: 0%;
  margin: 0 0px;
  border-width: 0;
  position: relative!important;
  background: #d6d6d6;
  border-radius: 0px 0px 5px 5px;
}
.app-header .dropdown-menu .dropdown-item{
  position: relative;
}
.app-header .dropdown-menu li.dropdown .dropdown-toggle.show::after{
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
.app-header .nav-link{
  max-width: 100%!important;
}
}

@media (min-width: 768px) {
  .dropdown-menu .dropdown .dropdown-menu {
    margin: 0;
    border-width: 1px;
  }

  .dropdown-menu > li a:hover,
  .dropdown-menu > li.show {
    background: #007bff;
    color: white;
  }
  .dropdown-menu > li.show > a {
    color: white;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .panel-tab .nav-link {
    padding: 0.5rem 1rem;
  }
}

@media (min-width: 992px) {
  .app-header .nav-item.dropdown .dropdown-menu {
    box-shadow: 0 4px 4px 0 rgb(0 0 0 / 10%);
    background-color: #fff;
    border-radius: 0px 0px 12px 12px;
    top: 102%; 
    z-index: 1030;
  }
  .app-header .nav-item.dropdown:hover .dropdown-menu {
    display: block;
  }
  .app-header .dropdown-menu li:last-child,
  .app-header .dropdown-menu li:last-child > a {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }
  .app-header .dropdown-menu li:last-child:hover > a {
    border-bottom-left-radius: 12px;
  }
  .app-header
    .nav-item.dropdown:hover
    .dropdown-menu
    li.dropdown
    > .dropdown-menu {
    display: none;
    border-radius: 0px 12px 12px 12px;
  }
  .app-header
    .nav-item.dropdown:hover
    .dropdown-menu
    li.dropdown:hover
    > .dropdown-menu {
    display: block;
    left: 100%;
    top: 0px;
  }
  .app-header
    .nav-item.dropdown:hover
    .dropdown-menu
    li.dropdown:hover
    .dropdown-menu
    li:nth-child(1)
    .dropdown-item {
    border-top-right-radius: 12px;
  }
  .app-header
    .nav-item.dropdown:hover
    .dropdown-menu
    li.dropdown:hover
    .dropdown-menu
    li:last-child
    .dropdown-item {
    border-bottom-right-radius: 12px;
  }
  .app-header,
  .app-content-root,
  .app-footer {
    padding: 0px 12px;
  }
  .w-lg-auto {
    width: auto !important;
  }
  .panel {
    height: calc(100vh - 135px);
  }
  .panel.master-setup {
    height: calc(100vh - 100px);
  }
  .tab-content .table-wrapper {
    height: calc(100vh - 284px);
  }
  .master-setup .table-wrapper {
    height: calc(100vh - 187px);
  }
  .master-setup .tab-content .table-wrapper {
    height: calc(100vh - 184px);
    /* height: calc(100vh - 238px); */
  }
  .dashboard-wrapper .card .card-body {
    height: calc(100vh - 192px);
  }
  .scroll-template-6 {
    height: calc(100vh - 318px) !important;
  }
  .leave-content.table-wrapper {
    height: calc(100vh - 295px);
  }
  .form-template {
    height: calc(100vh - 135px);
  }
  .master-setup .form-template {
    height: calc(100vh - 102px);
  }
  .transaction-table-wrapper {
    height: calc(100vh - 300px) !important;
  }
  .tab-body-content {
    height: calc(100vh - 314px) !important;
  }
  .main-scroll {
    height: calc(100vh - 135px) !important;
  }
  .table-wrapper.leave-eligibility {
    height: calc(100vh - 300px);
  }
  .table-wrapper.full-page {
    height: calc(100vh - 120px);
  }
  .table-wrapper.full-page .table-responsive {
    height: calc(100vh - 318px);
  }
  .stepper-tab-content.type-2 {
    height: calc(100vh - 182px);
    overflow-y: auto;
  }
  .stepper-tab-content.type-2 .table-responsive {
    height: calc(100vh - 325px);
  }
  .master-setup .stepper-tab-content {
    height: calc(100vh - 272px);
  }
  .card-body.body-scroll {
    height: calc(100vh - 352px);
  }
  .mon-emp-list .table-wrapper {
    height: calc(100vh - 400px);
  }
  .mon-emp-list .table-wrapper .table-responsive {
    height: 334px !important;
  }
  body.header-with-strip .panel {
    height: calc(100vh - 165px);
  }
  body.header-with-strip .table-wrapper {
    height: calc(100vh - 250px);
  }
  body.header-with-strip .tab-content .table-wrapper {
    height: calc(100vh - 304px);
  }
  body.header-with-strip .dashboard-wrapper .card .card-body {
    height: calc(100vh - 226px);
  }
  body.header-with-strip .scroll-template-6 {
    height: calc(100vh - 345px) !important;
  }
  body.header-with-strip .tab-content.leave-content .table-wrapper {
    height: calc(100vh - 380px);
  }
  body.header-with-strip .form-template {
    height: calc(100vh - 168px);
  }
  body.header-with-strip .transaction-table-wrapper {
    height: calc(100vh - 335px) !important;
  }
  body.header-with-strip .tab-body-content {
    height: calc(100vh - 349px) !important;
  }
  body.header-with-strip .main-scroll {
    height: calc(100vh - 170px) !important;
  }
  body.header-with-strip .stepper-tab-content {
    height: calc(100vh - 265px);
  }
  body.header-with-strip .leave-content.table-wrapper {
    height: calc(100vh - 325px);
  }
  body.header-with-strip .table-wrapper.leave-eligibility {
    height: calc(100vh - 330px);
  }
  body.header-with-strip .table-wrapper.full-page {
    height: calc(100vh - 150px);
  }
  body.header-with-strip .table-wrapper.full-page .table-responsive {
    height: calc(100vh - 300px);
  }
  body.header-with-strip .stepper-tab-content.type-2 {
    height: calc(100vh - 212px);
  }
  body.header-with-strip .stepper-tab-content.type-2 .table-responsive {
    height: calc(100vh - 352px);
  }
  body.header-with-strip .card-body.body-scroll {
    height: calc(100vh - 384px);
  }
  body.header-with-strip .mon-emp-list .table-wrapper {
    height: calc(100vh - 345px);
  }
  body.header-with-strip .panel.master-setup {
    height: calc(100vh - 130px);
  }
  body.header-with-strip .panel.master-setup .table-wrapper {
    height: calc(100vh - 212px);
  }
  body.header-with-strip .panel.master-setup .form-template {
    height: calc(100vh - 132px);
  }
  body.header-with-strip .panel.master-setup .stepper-tab-content {
    height: calc(100vh - 300px);
  }
  .tutorial-video-wrapper {
    height: calc(100vh - 190px) !important;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .navbar-brand {
    font-size: 1.6rem !important;
  }
  .app-header .nav-link {
    padding: 0rem 0.5rem !important;
    font-size: 15px;
  }
  .panel-header-template-5 .v-scroll {
    height: calc(100vh - 394px);
  }
}



/* @media (min-width: 1200px) and (max-width: 1439.98px) {
  .comp-name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 7ch;
  }
} */

@media only screen and (max-width: 1440px) and (min-width: 1200px)  {
  .payroll-sec .empWizard .panel {
    height: calc(100vh - 225px)!important;
  }
  .att-adj .empWizard .panel .att-table {
    height: calc(100vh - 400px)!important;
}
}



@media (-webkit-min-device-pixel-ratio: 1.5) {
  /*body {
    zoom: 0.88;
    -moz-transform: scale(0.85);
    -moz-transform-origin: 0 0;
  }*/

  header{
    zoom: 0.88;
    -moz-transform: scale(0.85);
    -moz-transform-origin: 0 0;
  }
  .app-content-root {
    zoom: 0.88;
    -moz-transform: scale(0.85);
    -moz-transform-origin: 0 0;
  }
  .modal-backdrop {
    width: 100% !important;
    height: 100% !important;
  }
  .panel {
    height: calc(100vh - 55px);
  }
  .table-wrapper {
    height: calc(100vh - 138px);
  }
  .dashboard-wrapper .card .card-body {
    height: calc(100vh - 115px);
  }
  .tab-content .table-wrapper {
    height: calc(100vh - 194px);
  }
  .form-template {
    height: calc(100vh - 55px);
  }
  .transaction-table-wrapper {
    height: calc(100vh - 205px) !important;
  }
  .scroll-template-6 {
    height: calc(100vh - 238px) !important;
  }
  .tab-content.leave-content .table-wrapper {
    height: calc(100vh - 275px);
  }
  .stepper-tab-content {
    height: calc(100vh - 160px);
  }
  .tab-inner-scroll {
    height: calc(100vh - 150px);
    overflow-y: auto;
  }
  .custom-date-picker.full-calander .ngb-dp-navigation-select {
    flex: 1 1 8rem;
  }
  .tab-body-content {
    height: calc(100vh - 234px) !important;
  }
  .main-scroll {
    height: calc(100vh - 55px) !important;
  }
  .table-wrapper.full-page {
    height: calc(100vh - 40px);
  }
  .table-wrapper.full-page .table-responsive {
    height: calc(100vh - 185px);
  }
  .form-template .body-scroll {
    height: calc(100vh - 275px);
  }
  .stepper-tab-content.type-2 {
    height: calc(100vh - 102px);
    overflow-y: auto;
  }
  .stepper-tab-content.type-2 .table-responsive {
    height: calc(100vh - 242px);
  }
  .mon-emp-list .table-wrapper {
    height: calc(100vh - 276px);
  }
  body.header-with-strip .table-wrapper.full-page {
    height: calc(100vh - 70px);
  }
  body.header-with-strip .table-wrapper.full-page .table-responsive {
    height: calc(100vh - 215px);
  }
  body.header-with-strip .panel {
    height: calc(100vh - 85px);
  }
  body.header-with-strip .dashboard-wrapper .card .card-body {
    height: calc(100vh - 148px);
  }
  body.header-with-strip .table-wrapper {
    height: calc(100vh - 172px);
  }
  body.header-with-strip .tab-content .table-wrapper {
    height: calc(100vh - 220px);
  }
  body.header-with-strip .form-template {
    height: calc(100vh - 86px);
  }
  body.header-with-strip .form-template .body-scroll {
    height: calc(100vh - 305px);
  }
  body.header-with-strip .stepper-tab-content {
    height: calc(100vh - 202px);
  }
  body.header-with-strip .tab-inner-scroll {
    height: calc(100vh - 180px);
    overflow-y: auto;
  }
  body.header-with-strip .stepper-tab-content.type-2 {
    height: calc(100vh - 132px);
  }
  body.header-with-strip .stepper-tab-content.type-2 .table-responsive {
    height: calc(100vh - 272px);
  }
  .leave-content.table-wrapper {
    height: calc(100vh - 215px);
  }
  body.header-with-strip .leave-content.table-wrapper {
    height: calc(100vh - 245px);
  }
  .table-wrapper.leave-eligibility {
    height: calc(100vh - 220px);
  }
  body.header-with-strip .table-wrapper.leave-eligibility {
    height: calc(100vh - 252px);
  }
  .panel.master-setup {
    height: calc(100vh - 20px);
  }
  .master-setup .form-template {
    height: calc(100vh - 24px);
  }
  .master-setup .table-wrapper {
    height: calc(100vh - 104px);
  }
  .master-setup .tab-content .table-wrapper {
    height: calc(100vh - 105px);
  }
  .master-setup .stepper-tab-content {
    height: calc(100vh - 140px);
  }
  body.header-with-strip .mon-emp-list .table-wrapper {
    height: calc(100vh - 267px);
  }
  body.header-with-strip .panel.master-setup {
    height: calc(100vh - 55px);
  }
  body.header-with-strip .panel.master-setup .table-wrapper {
    height: calc(100vh - 140px);
  }
  body.header-with-strip .panel.master-setup .form-template {
    height: calc(100vh - 58px);
  }
  body.header-with-strip .panel.master-setup .stepper-tab-content {
    height: calc(100vh - 230px);
  }
  .tutorial-video-wrapper {
    height: calc(100vh - 107px) !important;
  }

}

@media print {
  .header,
  .breadcrumb,
  .card-header {
    display: none !important;
  }
  .card {
    padding: 0px !important;
    box-shadow: none !important;
    border: none !important;
  }
}
.ds-badge{
  padding: 5px 8px 4px 8px !important;

}
.bg-green{
  background-color: #27ae60 !important;
}

/************ Media query screen size*********/
@media (min-width: 1200px) and (max-width: 1279.98px) { }
@media (min-width: 1280px) and (max-width: 1365.98px) { }
@media (min-width: 1366px) and (max-width: 1439.98px) { }
@media (min-width: 1440px) and (max-width: 1599.98px) { }



div:focus-visible {
  outline: 0px solid !important;
}

.active-body-class .v-scroll {
  overflow-y: hidden;
}