// @import "@coreui/coreui/scss/coreui";
@import "@coreui/coreui/scss/coreui";
@import "ag-grid-community/styles/ag-grid.css";
@import "ag-grid-community/styles/ag-theme-alpine.css";
@import "ag-grid-community/styles/ag-theme-material.css";

@import "~@fortawesome/fontawesome-free/css/all.css";
@import "digisme.css";
@import "theme.css";
@import "~@ng-select/ng-select/themes/default.theme.css";

// regular style toast
// @import "node_modules/ ~ngx-toastr/toastr";
@import "node_modules/ngx-toastr/toastr";
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
.modal-holder {
  animation-name: example;
  animation-duration: 0.3s;
}

@keyframes example {
  0% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
}

.ag-theme-custom {
  --ag-row-height: 40px;
  --ag-row-border-style: solid;
  --ag-row-border-width: 1px;
  --ag-row-border-color: #e0e0e0;
  --ag-background-color: white;
  --ag-odd-row-background-color: white;
  --ag-border-color: #e0e0e0;
  --ag-borders: solid 1px;
  --ag-input-focus-border-color: transparent;
  --ag-header-column-seperator-dispaly: block;
  --ag-header-column-separator-display: block;
  --ag-header-column-separator-height: 100%;
  --ag-header-column-separator-width: 1px;
  --ag-header-column-separator-color: #e0e0e0;
  --ag-cell-horizontal-padding: 8px;
  --ag-control-panel-background-color: #e9ecf5;
}

.ag-updatebyIndividual {
  --ag-row-height: 28px;
}

// .ag-reportGrip{
//   --ag-odd-row-background-color: #f9f8fb;
// }

.ag-theme-customscheduling {
  --ag-row-height: 0;
  --ag-row-border-style: solid;
  --ag-row-border-width: 0px;
  --ag-row-border-color: #ebedef;
  --ag-background-color: white;
  --ag-odd-row-background-color: white;
  --ag-cell-horizontal-padding: 0;
  // --ag-cell-horizontal-border: solid #ebedef;
}
// .ag-theme-alpine {
//   --ag-background-color: white;
//   --ag-header-background-color: #f4f4f6;
//   --ag-card-shadow: 0 0 0 0;
//   --ag-borders: solid 1px;
//   --ag-border-color:#e0e0e0;
//   --ag-border-radius:50px;
//   --ag-header-height:40px;
//   --ag-subheader-background-color:white;
//   --ag-odd-row-background-color:white;
//   --ag-row-height:30px;
//   --ag-card-radius:15px;
//   --ag-font-family:ProximaNova;
//   --ag-font-size:14px;

// }

.dp-hidden {
  width: 0;
  margin: 0;
  border: none;
  padding: 0;
}
.custom-day {
  text-align: center;
  padding: 0.185rem 0.25rem;
  display: inline-block;
  height: 2rem;
  width: 2rem;
}
.custom-day.focused {
  background-color: #e6e6e6;
}
.custom-day.range,
.custom-day:hover {
  background-color: rgb(2, 117, 216);
  color: white;
}
.custom-day.faded {
  background-color: rgba(2, 117, 216, 0.5);
}

.rag-red {
  background-color: #fad5d5;
}

.rag-orange {
  background-color: #fcf1c0;
}

.rag-rose {
  background-color: #d3fadf;
}

.rag-voilet {
  background-color: #e2b6ff;
}

.rag-green {
  background-color: #c9ead7 !important;
}

.rag-blue {
  background-color: #c5daf6;
}

.updateByBorder {
  border-color: #eb5757;
  border-width: 0px 0px 0px 3px;
  // --ag-cell-horizontal-border: solid #eb5757;
}

// .ag-theme-customscheduling .ag-cell-wrapper, .ag-theme-customscheduling .ag-cell-value {
//   height: 100%;
//   background-color:red;
// }
.row-error {
  background-color: #ffefef;
}

.ag-theme-custom app-grid-time-editor {
  width: 100%;
}

.ag-theme-custom app-ag-grid-dropdown {
  width: 100%;
}

.cdk-visually-hidden {
  position: absolute !important;
}

.schedule-table-wrapper .ag-root-wrapper,
.custom-popup .ag-root-wrapper {
  overflow: visible;
}

.rag-red input {
  background-color: #fad5d5;
}
.rag-blue input {
  background-color: #c5daf6;
}
.input_fileupload--hidden {
  display: none;
}
.grid_header {
  font-weight: 500;
  color: #456499;
  background-color: #d9eaff;
}

.ag-cell-wrap-text {
  word-break: break-word;
  // padding: 10px;
  vertical-align: middle;
  border: none;

}
.ag-cell-value, .ag-group-value {
 overflow: hidden;
  text-overflow: ellipsis;
  line-height: 2;
  font-weight: 500 !important;
  // line-height: 15px;
  }

.ag-group-header .ag-header-group-cell-label {
  text-align: center;
  justify-content: center;
}

ng-select {
  scroll-margin: 80px;
}
.ag-col-hide .ag-header {
  display: none;
}

.mandatory {
  background-color: #fad5d5;
}

.ag-editable .editable span.ag-cell-value {
  border: 1px solid#0000004a;
  padding: 5px 5px;
  height: 27px;
}

.ag-editable .ag-cell.non-editable {
  border: 1px solid#0000;
  background: #eee9e9f2;
}

.ag-editable .ag-cell {
  padding: 2px 2px;
}

.ag-editable .ag-row {
  border-bottom: 0px;
}

.mat-datepicker-content {
  z-index: 1200;
}

.cdk-overlay-container {
  z-index: 2000;
}

.ag-header-cell.text-center {
  .ag-header-cell-label {
    justify-content: center;
  }
}
.cellCenter .ag-cell-wrapper {
  justify-content: center;
}

.accordion-item:nth-of-type(1) {
  z-index: 999;
  position: relative;
}

.accordion-item:nth-of-type(2) {
  z-index: 99;
  position: relative;
}

.accordion-item:nth-of-type(3) {
  z-index: 9;
  position: relative;
}

ngb-tooltip-window {
  z-index: 999;
  position: relative;
}

#toast-container {
  --bs-toast-zindex: 9999;
}
// .amountInput {
//   text-align: end!important;
// }
.no-wrap-header {
  white-space: nowrap;
}
.ag-theme-alpine .ag-root-wrapper {
  overflow-x: hidden;
}
.timeInput {
  text-align: center;
}
.GridFooter {
  background-color: #8c8c8c !important;
  color: white;
  font-weight: 600;
}
.go-to-page.ng-pristine {
  border-top-left-radius: 1rem !important ;
  border-bottom-left-radius: 1rem !important ;
}
